import React from 'react';
import TveButton from './TveButton';
import { CREATEINWEST, CreateIcon } from '../Config/TveActions';

const CreateInWestTveButton = (props) => (
  <TveButton
    label="Create TVE in West Region"
    color="customUniqueButton"
    icon={<CreateIcon />}
    action={CREATEINWEST}
    confirm={true}
    fromTveStatuses={['Not Created', 'Deleted']}
    fromTrainingStatuses={['Not Started', 'In Progress']}
    fromCanesVersions="ALL"
    {...props}
  />
);

export default CreateInWestTveButton;
