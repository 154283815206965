import React from 'react';
import TveButton from './TveButton';
import { CREATEINEAST, CreateIcon } from '../Config/TveActions';

const CreateInEastTveButton = (props) => (
  <TveButton
    label="Create TVE in East Region"
    color="customUniqueButton"
    icon={<CreateIcon />}
    action={CREATEINEAST}
    confirm={true}
    fromTveStatuses={['Not Created', 'Deleted']}
    fromTrainingStatuses={['Not Started', 'In Progress']}
    fromCanesVersions="ALL"
    {...props}
  />
);

export default CreateInEastTveButton;
