import React from 'react';
import {
  InfiniteList,
  InfinitePagination,
  useListContext,
  DatagridConfigurable,
  SelectColumnsButton,
  TextField,
  TopToolbar,
  CreateButton,
  DateField,
  FunctionField,
  TextInput,
  useGetList,
  usePermissions,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import CreateTveButton from '../Components/CreateTveButton';
import CreateInEastTveButton from '../Components/CreateInEastTveButton';
import CreateInWestTveButton from '../Components/CreateInWestTveButton';
import StartTveButton from '../Components/StartTveButton';
import PauseTveButton from '../Components/PauseTveButton';
import DeleteTveButton from '../Components/DeleteTveButton';
import DropStudentButton from '../Components/DropStudentButton';
import CompleteStudentTrainingButton from '../Components/CompleteStudentTrainingButton';
import ExtendScheduledTimeButton from '../Components/ExtendScheduledTimeButton';
import TriggerFinalPracticumButton from '../Components/TriggerFinalPracticumButton';
import TveStatus from '../Components/TveStatus';
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';
import { Container, Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

const Wrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '365px auto',
  gridGap: '10px 2em',
});

const CustomPagination = () => {
  const { total, data, isLoading } = useListContext();

  if (isLoading) {
    return <CircularProgress />;
  }

  const instructorCount = data.reduce((set, obj) => {
    if (obj.stackUserType === 'Instructor' && obj.createdTime !== null) {
      set.add(obj.student?.username);
    }
    return set;
  }, new Set()).size;

  const studentCount = data.reduce((set, obj) => {
    if (obj.stackUserType === 'Student' && obj.createdTime !== null) {
      set.add(obj.student?.username);
    }
    return set;
  }, new Set()).size;

  return (
    <>
      <InfinitePagination />
      {total > 0 && (
        <Box position="sticky" bottom={0} textAlign="center">
          <Card
            elevation={2}
            sx={{ px: 2, py: 1, mb: 1, display: 'inline-block', backgroundColor: 'lightblue' }}
          >
            <Typography align="left">
              {studentCount} {studentCount === 1 ? 'student' : 'students'}, {instructorCount}{' '}
              {instructorCount === 1 ? 'instructor' : 'instructors'}{' '}
            </Typography>
          </Card>
        </Box>
      )}
    </>
  );
};

const Empty = () => (
  <Container>
    <Wrapper>
      <Box textAlign="left" m={1}>
        <Typography variant="h6">No records found</Typography>
      </Box>
      <Box textAlign="right" m={1}>
        <CreateButton label="Add Record" to={`create`} variant="contained" />
      </Box>
    </Wrapper>
  </Container>
);

// if any stack is in "Rollback In Progress" status, do not allow any stack actions (create, etc.)
const BulkActionButtons = () => {
  const { permissions } = usePermissions();
  let { classId } = useParams();

  const { data, isLoading, error } = useGetList('tveclassappusersbyclass', {
    filter: { tveclassid: classId },
  });
  if (isLoading) return <CircularProgress size="2rem">classId</CircularProgress>;
  if (error) return error;

  const shouldHideBulkActionButtons = data.some(
    (obj) => obj.tveStatus.name === 'Rollback In Progress'
  );

  if (shouldHideBulkActionButtons) {
    return (
      <span>
        <Box sx={{ width: '100%', color: 'red' }}>
          No actions available while a stack in the class is in Rollback status
        </Box>
      </span>
    );
  }

  return (
    <>
      <TriggerFinalPracticumButton />
      <CreateTveButton />
      {permissions === 'ADMIN' && (
        <>
          <CreateInEastTveButton />
          <CreateInWestTveButton />
        </>
      )}
      <StartTveButton />
      <PauseTveButton />
      <DeleteTveButton />
      <DropStudentButton />
      <CompleteStudentTrainingButton />
      <ExtendScheduledTimeButton />
    </>
  );
};

const ListActions = () => {
  return (
    <TopToolbar>
      <CreateButton label="Add Record" to={`create`} variant="contained" />
      <SelectColumnsButton variant="contained" />
    </TopToolbar>
  );
};

// If the stack version performance test is not enabled, show "N/A" for this column
const translateFinalTriggered = (canesversionhasperformancetest, finalTriggered) => {
  if (canesversionhasperformancetest && canesversionhasperformancetest === 'Y') {
    if (finalTriggered === null) {
      return null;
    } else {
      return new Date(finalTriggered).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short',
      });
    }
  } else {
    return 'N/A';
  }
};

const tveClassAppUserFilters = [
  <TextInput label="Filter by Stack User Type" source="stackUserType" alwaysOn />,
  <TextInput label="Stack Version" source="canesVersion.name" alwaysOn />,
  <TextInput label="Training Status" source="appUserTrainingStatus.name" alwaysOn />,
  <TextInput label="TVE Status" source="tveStatus.name" alwaysOn />,
];

const TveClassAppUserList = (props) => {
  return (
    <InfiniteList
      pagination={<CustomPagination />}
      title="Students"
      sort={{ field: 'tveClassId', order: 'DESC' }}
      filters={tveClassAppUserFilters}
      exporter={false}
      actions={<ListActions />}
      // props needed to get the resource prop
      {...props}
      perPage={25}
      empty={<Empty />}
    >
      <DatagridConfigurable
        bulkActionButtons={<BulkActionButtons />}
        rowClick={(id) => `${id}`}
        omit={['studentName', 'createTveAppUser.username', 'tveStartupTime', 'createdTime']}
      >
        <TextField source="tveClassAppUserId" label="Id" />
        <TextField source="studentName" label="Description" />
        <TextField source="student.username" label="Student Username" />
        <TextField source="stackUserType" label="Stack User Type" />
        <TextField source="trainingType" label="Training Type" />
        <TextField source="createTveAppUser.username" label="Created By" />
        <TextField source="canesVersion.name" label="Stack Version" />
        <TextField source="appUserTrainingStatus.name" label="Training Status" />
        <TveStatus source="tveStatus.name" label="TVE Status" refreshInterval={5000} />
        <TextField source="tveStartupTime" label="Start Time" />
        <TextField source="tvePauseTime" label="Pause Time" />
        <DateField source="createdTime" label="Created Time" showTime />
        <FunctionField
          label="Performance Test Last Triggered"
          render={(record) =>
            // translateFinalTriggered(record?.canesVersion?.name, record.finalTriggered)
            translateFinalTriggered(record?.canesVersion?.hasPerformanceTest, record.finalTriggered)
          }
          sortBy="finalTriggered"
        />
      </DatagridConfigurable>
    </InfiniteList>
  );
};

export default TveClassAppUserList;
