import {
    FINAL,
    CREATEINEAST,
    CREATEINWEST,
    CREATE,
    START,
    PAUSE,
    DELETE,
    DROP,
    COMPLETE,
    EXTEND
} from '../Config/TveActions'

const actionEndpoints = {
    [FINAL]:     'triggerFinalPracticum',
    [CREATE]:    'createTVE',
    [CREATEINEAST]:    'createInEastTVE',
    [CREATEINWEST]:    'createInWestTVE',
    [START]:     'startTVE',
    [PAUSE]:     'pauseTVE',
    [DELETE]:    'deleteTVE',
    [DROP]:      'dropStudent',
    [COMPLETE]:  'completeStudentTraining',
    [EXTEND]:    'extendTvePauseTime'
}

const updateTve = (tveClassAppUserId, tve, config) => {
    const { fetchClient, apiUrl } = config
    if (!(tve.action in actionEndpoints))
        return Promise.resolve()

    return fetchClient({
        method: 'get',
        url: `${apiUrl}/aws/${actionEndpoints[tve.action]}/${tveClassAppUserId}`
    }).then(
        response => response.data
    )
}

export default updateTve
